import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import PersonalPage from "../../components/common/PersonalPage"

export default function Personal() {
  const data = useStaticQuery(graphql`
    {
      wpPage(databaseId: { eq: 31 }) {
        title
        uri
        personal {
          personer {
            titel
            fornamn
            efternamn
            bild {
              localFile {
                childImageSharp {
                  gatsbyImageData(aspectRatio: 0.66, width: 400)
                }
              }
            }
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO title="Personal" />
      <PersonalPage data={data} />
    </Layout>
  )
}
